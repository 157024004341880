//
//
//
//
// no se utiliza por que se unificó en un solo formulario el select y el nombre del cliente y se tratan con los controladores buscar_controller.js y buscar_productos_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  //connect() {
  //alert('ok')
  //}
  connect() {

  }

  //change(event) {
    //const frame = document.getElementById('p_seleccionados');
    //frame.src=event.target.value;
    //frame.reload();
  //}
  cambiar_pedidos(event) {
    let frame = document.getElementById('p_seleccionados')
    frame.src =  event.currentTarget.value
    //frame.src=event.target.value;

    // el valor es algo como:
    //pedidos/filtros_suc_ruta?filtro=2&suc_ruta=BAh7CEkiCGdpZAY6BkVUSSIcZ2lkOi8vY29udHJvbC9BbG1hY2VuLzEGOwBUSSIMcHVycG9zZQY7AFRJIgxkZWZhdWx0BjsAVEkiD2V4cGlyZXNfYXQGOwBUSSIdMjAyNC0wNC0yMlQxODo0MjowNC4wMDhaBjsAVA%3D%3D--6281f056f89c312dabd638b434e92a10216d99f5
    // de lo cual neccesitamos el valor que esta despues de "filtro"
    // para lo extraemos la cadena con el sisuignete metodo:
    // extraer la cadena posocionada entre la posicion 33 y 35 osea el 34 (en la cadena anterior es el numero 2, despues de la palabra filtro=)
    let status_pedido = frame.src.substring(33, 34);
    // le asigna el valor anterior al input con id: estado_p
    $("#estado_p").val(status_pedido);

    frame.reload()
  }

cambiar_remisiones(event) {
    let frame = document.getElementById('v_seleccionadas')
    frame.src =  event.currentTarget.value
    //frame.src=event.target.value;

    // el valor es algo como:
    //pedidos/filtros_suc_ruta?filtro=2&suc_ruta=BAh7CEkiCGdpZAY6BkVUSSIcZ2lkOi8vY29udHJvbC9BbG1hY2VuLzEGOwBUSSIMcHVycG9zZQY7AFRJIgxkZWZhdWx0BjsAVEkiD2V4cGlyZXNfYXQGOwBUSSIdMjAyNC0wNC0yMlQxODo0MjowNC4wMDhaBjsAVA%3D%3D--6281f056f89c312dabd638b434e92a10216d99f5
    // de lo cual neccesitamos el valor que esta despues de "filtro"
    // para lo extraemos la cadena con el sisuignete metodo:
    // extraer la cadena posocionada entre la posicion 33 y 35 osea el 34 (en la cadena anterior es el numero 2, despues de la palabra filtro=)
    let status_remision = frame.src.substring(36, 37);
    // le asigna el valor anterior al input con id: estado_p
    $("#estado_r").val(status_remision);
    frame.reload()
  }
}
