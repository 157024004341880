import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  clear() {
    //this.element.reset()
    let frame= document.getElementById("search_item");
    frame.value= "";
    console.log("Hello, 2!", this.element)

  }
}
