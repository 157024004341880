import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  cambiar() {
    var id_seleccionado = this.element.value
    if (id_seleccionado == "true"){
      console.log("Hello, frvvvvvvvente!", id_seleccionado)
        
  }
  else{
    $("#gas").empty()
  }

  console.log("Hello, frente!", id_seleccionado)


  }

}
