import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  //connect() {
  //alert('ok')
  //}
  greet() {
  console.log("Hello, Stimulus!", this.element)
}
}
