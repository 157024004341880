import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {

  }

  greet() {
    var id_seleccionado = this.element.value
    if (id_seleccionado == 2){
    $("#gas").empty().html(`
      <small class="text-muted">Kilometraje de vehículo al cargar combustible:</small>
        <div class="input-group">

             <input class="form form-control" min="1" step="1" required="required" type="number" name="gasto[kilometraje]" id="gasto_kilometraje" />
             <div class="input-group-prepend">
               <span class="input-group-text">Kilometros</span>
             </div>
        </div>
      <br>
    `);
  }
  else{
    $("#gas").empty()
  }

  //console.log("Hellqqqqo, gasobbbblina!", id_seleccionado)


  }

}
