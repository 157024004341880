import { Controller } from "@hotwired/stimulus";
//oculta las alertas
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.dismiss()
    }, 5000)
  }

  dismiss() {
    this.element.remove()
  }
}
