import { Controller } from "@hotwired/stimulus"
//import debounce from "debounce";
//los eventos de stimulus funcionan para los elementos antes de ser remplazados, no funcionan para los nuevos
export default class extends Controller {
  //static targets=["lab_cantidad_producto"]
  connect() {
      //alert('ok')
  }
  actualizar(_event){
    // podria ser solo:
    //  this.element.requestSubmit()
//pero se implementa lo siguiente para que no se genere una consulta cada que se presiona una tecla si no cuando pasen 500 mlsegundos sin apretarla

    clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.element.requestSubmit()
      }, 500)

  }


}
