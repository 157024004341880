$('#select_almacen').on('change', function() {
    alert( this.value );
    $.ajax({
        url: "/almacenes/find_almacen",
        type: "get",
        data: {
            "almacen_id": "1"
        },
        dataType: 'script',
        success: function (data) {
            console.log("Ajax OK")
        },
        error: function (data) {
            console.log("Error en petición OK!")
        }
    });
});