import { Controller } from "@hotwired/stimulus"
//los eventos de stimulus funcionan para los elementos antes de ser remplazados, no funcionan para los nuevos
export default class extends Controller {
  //static targets=["lab_cantidad_producto"]
  connect() {
  //alert('ok')
  let val_modal = this.data.get("nume");
    console.log(val_modal)
    //lo siguiente funciona al igual que el metodo cerrar modal:
    //this.element.addEventListener('turbo:submit-end', (event) => {
              //if (event.detail.success) {
              //this.cerrar_modal()
            //}
        //});
  }
// el metodo se puede llamar desde un elemento que este dentro de un controlador en html
// ejemplo:  data: {action: "click->producto#clic_guardar"}
  clic_guardar() {
    let val_modal = this.data.get("nume");
                this.cerrar_modal()
  }
cerrar_modal() {
  let val_modal = this.data.get("nume");
  $('#modal_de_item' + val_modal).modal('hide');
    //elemento con id: modal_de_item
    // lo que hace es ocultar el modal
}
agregar_efecto()
  {      //this.lab_cantidad_productoTarget.classList.add("bg-danger")
    console.log("ahí vaaaaaaaa")

        $("#www").addClass("bg-success");

  }
}
