import { Controller } from "@hotwired/stimulus"
//import debounce from "debounce";
//los eventos de stimulus funcionan para los elementos antes de ser remplazados, no funcionan para los nuevos
export default class extends Controller {
  //static targets=["lab_cantidad_producto"]
  connect() {

  }
  actualizar(_event){
    // podria ser solo:
    //  this.element.requestSubmit()
//pero se implementa lo siguiente para que no se genere una consulta cada que se presiona una tecla si no cuando pasen 500 mlsegundos sin apretarla

    clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.element.requestSubmit()
      }, 500)
      //cada vez que se ejecute el metodo ocultará el boton de cambiar cliente
      //para que no mande error (para que no se ejecute cambiar cliente y no se tenga nigun cliente seleccionado)
      $('#bt_save').addClass('hidden');
  }


}
